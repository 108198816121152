/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

export const Footer: React.FC = () => {
  return (
    <footer className="bg-secondary px-4 h-10">
      <div className="container mx-auto flex justify-center items-center h-full">
        <p className="text-sm text-gray-500">
          {"Copyright © "}
          <a href="" className="hover:underline">
            Breakfast Briefs
          </a>{" "}
          {new Date().getFullYear()}
          {"."}
        </p>
      </div>
    </footer>
  );
};
