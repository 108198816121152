import React from "react";
import { supabase } from "../../lib/SupabaseClient";
import { useAuth } from "../../lib/AuthContext";

export type NavbarProps = {
  minimal: boolean;
};

const Navbar: React.FC<NavbarProps> = ({ minimal }) => {
  const { session } = useAuth();

  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) console.log("Error logging out:", error.message);
  };
  const navHeightCss = minimal ? "h-8" : "h-16";
  const navPaddingCss = minimal ? "py-2 px-12" : "p-4";

  return (
    <nav className={`bg-secondary ${navPaddingCss} ${navHeightCss}`}>
      <div className="container mx-auto flex justify-between items-center h-full">
        <div>
          <span className="text-primary-light text-lg font-semibold">
            Breakfast Briefs
          </span>
        </div>
        {session && session.user && (
          <div>
            <button
              onClick={handleLogout}
              className="text-secondary bg-primary-dark hover:bg-primary px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-light"
            >
              Logout
            </button>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
