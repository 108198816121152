import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const usePosthog = process.env.REACT_APP_USE_POSTHOG === "1";

if (usePosthog) {
  posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY || "", {
    api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
    person_profiles: "always",
  });
  console.log("Posthog initialized.");
} else {
  console.log("Posthog disabled - skipping initialization.");
}

root.render(
  <React.StrictMode>
    {usePosthog ? (
      <PostHogProvider client={posthog}>
        <App />
      </PostHogProvider>
    ) : (
      <App />
    )}
  </React.StrictMode>
);
