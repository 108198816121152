import React from "react";

const SettingsSection: React.FC = () => (
  <div>
    <h3 className="text-2xl font-semibold mb-4">Settings</h3>
    <p>This is the settings section.</p>
  </div>
);

export default SettingsSection;
