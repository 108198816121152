import { Footer } from "./Footer";
import Navbar from "./Navbar";

export type LayoutProps = {
  minimal?: boolean;
  children: React.ReactNode;
};

export const Layout: React.FC<LayoutProps> = ({
  minimal = false,
  children,
}) => {
  return (
    <div className="flex flex-col min-h-screen bg-secondary">
      <header className="p-4 bg-secondary-dark">
        <Navbar minimal={minimal} />
      </header>
      <main className="bg-secondary flex-1 flex">{children}</main>
      {!minimal && (
        <footer className="p-4 bg-secondary-dark">
          <Footer />
        </footer>
      )}
    </div>
  );
};
