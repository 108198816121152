import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../lib/AuthContext";

const ProtectedRoute: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const { isLoggedIn, loading } = useAuth();

  // Loading and app not in test mode
  if (loading && process.env.REACT_APP_TEST_MODE === "0") {
    console.log("Loading");
    return <div>Loading...</div>;
  }

  // Not logged in and app not in test mode
  if (!isLoggedIn && process.env.REACT_APP_TEST_MODE === "0") {
    console.log("Unauth");
    return <Navigate to="/" />;
  }

  return children;
};

export default ProtectedRoute;
