import React, { useState } from "react";
import { supabase } from "../lib/SupabaseClient";
import { Layout } from "../components/common/Layout";

const Login: React.FC = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    const { error } = await supabase.auth.signInWithOtp({
      email,
      options: {
        emailRedirectTo: `${process.env.REACT_APP_BASE_URL}/dashboard`,
      },
    });
    setLoading(false);
    if (error) {
      setMessage(
        "There was an error sending the magic link. Please try again."
      );
    } else {
      setMessage(
        "If an account exists, a login link has been sent to your email. If this is your first time, an account will be created, and you’ll receive a link to log in."
      );
    }
  };

  return (
    <Layout>
      <div className="flex items-center justify-center bg-secondary text-neutral w-full">
        <div className="bg-secondary-light p-12 rounded-2xl shadow-2xl w-full max-w-md">
          <h2 className="text-3xl font-semibold mb-4 text-primary-dark">
            Welcome Back
          </h2>
          <p className="text-base text-primary mb-8">
            Enter your email below, and we’ll send you a magic link to access
            your account. If you’re new here, we’ll create an account for you.
          </p>
          <form onSubmit={handleLogin} className="space-y-6">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-primary-light mb-2"
              >
                Email Address
              </label>
              <input
                type="email"
                id="email"
                className="mt-1 block w-full px-4 py-3 border border-neutral-light rounded-md shadow-sm focus:ring-accent focus:border-accent text-secondary"
                placeholder="name@example.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <button
              type="submit"
              className={`w-full py-3 px-4 text-secondary bg-primary-dark rounded-md focus:outline-none focus:ring-2 focus:ring-primary-dark transition duration-150 ease-in-out ${
                loading
                  ? "bg-primary-light cursor-not-allowed"
                  : "hover:bg-primary"
              }`}
              disabled={loading}
            >
              {loading ? "Sending Link..." : "Send Magic Link"}
            </button>
          </form>
          {message && (
            <p className="mt-6 text-sm text-primary-dark">{message}</p>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Login;
