import React, { useState } from "react";
import "./ComingSoon.css";
import { Layout } from "../components/common/Layout";
import { supabase } from "../lib/SupabaseClient";

const ComingSoon: React.FC = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleEmailEntered = async (e: React.FormEvent) => {
    setLoading(true);
    e.preventDefault();

    const { error } = await supabase
      .from("waitlist")
      .insert([{ email: email, src_url: process.env.REACT_APP_BASE_URL }]);

    if (error) {
      setMessage(
        "There was an error adding you to the waitlist. Please try again later."
      );
    } else {
      setMessage(
        "You're on the waitlist! We'll reach out when Breakfast Briefs is ready to start serving fresh hot news every morning to your inbox."
      );
      setIsSubmitted(true);
    }

    // Set loading false
    setLoading(false);
  };
  return (
    <Layout>
      <div className="flex items-center justify-center bg-secondary text-neutral w-full">
        <div className="bg-secondary-light p-12 rounded-2xl shadow-2xl w-full max-w-md">
          {!isSubmitted ? (
            <>
              <h2 className="text-3xl font-semibold mb-4 text-primary-dark">
                ✋ Hold Up
              </h2>
              <p className="text-base text-primary mb-8">
                We're currently in private beta. Enter your email below, and
                we’ll reach out when Breakfast Briefs is ready to start serving
                fresh hot news every morning to your inbox.
              </p>
              <form onSubmit={handleEmailEntered} className="space-y-6">
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-primary-light mb-2"
                  >
                    Email Address
                  </label>
                  <input
                    type="email"
                    id="email"
                    className="mt-1 block w-full px-4 py-3 border border-neutral-light rounded-md shadow-sm focus:ring-accent focus:border-accent text-secondary"
                    placeholder="name@example.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <button
                  type="submit"
                  className={`w-full py-3 px-4 text-secondary bg-primary-dark rounded-md focus:outline-none focus:ring-2 focus:ring-primary-dark transition duration-150 ease-in-out ${
                    loading
                      ? "bg-primary-light cursor-not-allowed"
                      : "hover:bg-primary"
                  }`}
                  disabled={loading}
                >
                  {loading ? "Saving..." : "Join Waitlist"}
                </button>
              </form>
              {message && (
                <p className="mt-6 text-sm text-primary-dark">{message}</p>
              )}
            </>
          ) : (
            <>
              <h2 className="text-3xl font-semibold mb-4 text-primary-dark">
                🤝 Nice
              </h2>
              <p className="mt-6 text-sm text-primary-dark">{message}</p>
            </>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default ComingSoon;
