import React, { useState } from "react";
import { AuthProvider } from "./lib/AuthContext";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import Welcome from "./pages/Welcome";
import ProtectedRoute from "./components/ProtectedRoute";
import ComingSoon from "./pages/ComingSoon";

const App: React.FC = () => {
  // State variable to check if app "is live"
  const [appIsLive] = useState<boolean>(process.env.REACT_APP_IS_LIVE === "1");

  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={appIsLive ? <Login /> : <ComingSoon />} />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Welcome />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;
