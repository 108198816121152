import React from "react";

const DashboardSection: React.FC = () => (
  <div>
    <div className="bg-secondary-light p-12 rounded-2xl shadow-2xl w-full max-w-md">
      <h2 className="text-3xl font-semibold mb-4 text-primary-dark">
        Dashboard
      </h2>
      <p className="italic">You're not subscribed to any topics yet.</p>
      <button
        type="submit"
        className={`mt-4 py-3 px-4 text-secondary bg-primary-dark rounded-md focus:outline-none focus:ring-2 focus:ring-primary-dark transition duration-150 ease-in-out ${"hover:bg-primary"}`}
      >
        Add new topic
      </button>
    </div>
  </div>
);

export default DashboardSection;
