import React, { useState } from "react";
import Sidebar from "../components/common/Sidebar";
import DashboardSection from "../components/sections/Dashboard";
import SettingsSection from "../components/sections/Settings";
import { Layout } from "../components/common/Layout";

const Welcome: React.FC = () => {
  const [activeSection, setActiveSection] = useState<string>("dashboard");

  const renderSection = () => {
    switch (activeSection) {
      case "dashboard":
        return <DashboardSection />;
      case "settings":
        return <SettingsSection />;
      default:
        return <DashboardSection />;
    }
  };

  // console.log(session);

  return (
    <Layout minimal={true}>
      <Sidebar
        activeSection={activeSection}
        setActiveSection={setActiveSection}
      />
      <main className="flex-grow px-10 bg-secondary text-primary-light">
        {renderSection()}
      </main>
    </Layout>
  );
};

export default Welcome;
