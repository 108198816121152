import React, { Dispatch } from "react";

const Sidebar: React.FC<{
  activeSection: string;
  setActiveSection: Dispatch<React.SetStateAction<string>>;
}> = ({ activeSection, setActiveSection }) => {
  const activeButtonCss =
    "bg-primary-dark text-secondary drop-shadow font-semibold";
  const inactiveButtonCss = "bg-primary text-secondary";
  const commonButtonCss = "w-full text-left px-6 py-4";

  return (
    <aside className="w-64 bg-primary rounded-2xl shadow-2xl">
      <ul>
        <li>
          <button
            className={`${commonButtonCss} rounded-t-2xl ${
              activeSection === "dashboard"
                ? activeButtonCss
                : inactiveButtonCss
            }`}
            onClick={() => setActiveSection("dashboard")}
          >
            Dashboard
          </button>
        </li>
        <li>
          <button
            className={`${commonButtonCss} ${
              activeSection === "settings" ? activeButtonCss : inactiveButtonCss
            }`}
            onClick={() => setActiveSection("settings")}
          >
            Settings
          </button>
        </li>
      </ul>
    </aside>
  );
};

export default Sidebar;
